import I from "immutable";

import { LOGOUT, END_LOGIN_FAIL } from "./account";

const DEFAULT_STATE = I.fromJS({
  items: [],
  loading: true
});

const START_LOAD = "app/consumptions/startLoad";
const END_LOAD_SUCCESS = "app/consumptions/endLoadSuccess";
const END_LOAD_FAIL = "app/consumptions/endLoadFail";

const ACTIONS = {
  [LOGOUT](state, payload) {
    return state.merge({
      items: I.List([]),
      loading: true
    });
  },
  [START_LOAD](state, payload) {
    return state.set("loading", true);
  },
  [END_LOAD_SUCCESS](state, { items }) {
    return state.merge({
      loading: false,
      items: I.List(items)
    });
  },
  [END_LOGIN_FAIL](state, payload) {
    return state.set("loading", false);
  }
};

function reducer(state = DEFAULT_STATE, action = {}) {
  const callback = ACTIONS[action.type];
  if (typeof callback === "function") {
    return callback(state, action.payload);
  }
  return state;
}

function startLoad() {
  return {
    type: START_LOAD,
    payload: {}
  };
}

function endLoadFail() {
  return {
    type: END_LOAD_FAIL,
    payload: {}
  };
}

function endLoadSuccess(items) {
  return {
    type: END_LOAD_SUCCESS,
    payload: {
      items
    }
  };
}

export { START_LOAD, END_LOAD_FAIL, END_LOAD_SUCCESS };

export { startLoad, endLoadFail, endLoadSuccess };

export default reducer;
