import React, { Component } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { Link } from "react-router-dom";
import faUser from "@fortawesome/fontawesome-free-solid/faUser";
import faKey from "@fortawesome/fontawesome-free-solid/faKey";

import LoadableView from "../components/LoadableView";
import Field from "../components/Field";
import PasswordField from "../components/PasswordField";
import SubmitButton from "../components/SubmitButton";
import FormPanel from "../components/FormPanel";

import withAlerts from "../components/withAlerts";

import Server from "../utils/server";

import { startLogin, endLogin } from "../reducers/account";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from 'react-google-login';

import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons/faGoogle";
class AccountLoginPage extends Component {
  state = {
    username: "",
    password: ""
  };

  formRef = React.createRef();

  onUsernameChange = event => {
    this.setState({ username: event.target.value });
  };
  onPasswordChange = event => {
    this.setState({ password: event.target.value });
  };

  onSubmit = event => {
    event.preventDefault();
    const { dispatch, alerts } = this.props;
    const { username, password } = this.state;

    try {
      window.Android.setLoginInfo(username, password);
    } catch (e) {
      console.log("no es dispositivo movil");
    }



    dispatch(startLogin());
    Server.logIn(username, password)
      .then(profile => {
        dispatch(endLogin(true, profile));
        alerts.success(`Hola, ¡${profile.name}!`);
        dispatch(replace("/app"));
      })
      .catch(error => {
        dispatch(endLogin(false));
        alerts.danger(error.message);
      });
  };









  componentDidMount() {

    const { dispatch, alerts } = this.props;
    let tipoLogin = null;
    let fromApp = false;
    let type= ""
    try {

      type = window.Android.getType();
     fromApp=true;
      

    } catch (e) {
      console.log("No es aplicacion")
      fromApp = false;
    }
    if (fromApp) {

      switch (type) {
        case "password":

          
          let username = window.Android.getUsername();
          let password = window.Android.getPassword();
          dispatch(startLogin());
          Server.logIn(username, password)
            .then(profile => {
              dispatch(endLogin(true, profile));

              dispatch(replace("/app"));
            })
            .catch(error => {
              dispatch(endLogin(false));
              alerts.danger(error.message);
            });
          break;

        case "facebook":
          let managementId = window.Android.getManagementId();
          dispatch(startLogin());

          
          Server.loginFacebook(managementId,false)
            .then(profile => {
              dispatch(endLogin(true, profile));

              dispatch(replace("/app"));
            })
            .catch(error => {
              dispatch(endLogin(false));
              alerts.danger(error.message);
            });
          break;

          case "google":
          let management = window.Android.getManagementId();
          dispatch(startLogin());

          
          Server.loginGoogle(management,false)
            .then(profile => {
              dispatch(endLogin(true, profile));

              dispatch(replace("/app"));
            })
            .catch(error => {
              dispatch(endLogin(false));
              alerts.danger(error.message);
            });
          break;



      }

    } else {
      const form = this.formRef.current;
      setTimeout(() => {
        this.setState({
          username: form.username.value,
          password: form.password.value
        });
      });
    }


   
  }
  facebookResponse = (response) => {

    console.log(response);

    const { alerts, dispatch } = this.props;
    dispatch(startLogin());
    Server.loginFacebook(response,true)
      .then(profile => {
        dispatch(endLogin(true, profile));
        
        try {
          window.Android.setManagementId(profile.id);
          window.Android.setType("facebook");
        } catch (e) {
          console.log("no es dispositivo movil");
        }
        dispatch(replace("/app"));
      })
      .catch(error => {
        dispatch(endLogin(false));
        alerts.danger(error.message);
      });




  };

  googleResponse = (response) => {
    console.log(response);

    window.Android.showToast("SE LOGEO CORRECTAMENTE")
    const { alerts, dispatch } = this.props;
    dispatch(startLogin());
    Server.loginGoogle(response,true)
      .then(profile => {
        dispatch(endLogin(true, profile));
        
        try {
          window.Android.setManagementId(profile.id);
          window.Android.setType("google");
        } catch (e) {
          console.log("no es dispositivo movil");
        }
        dispatch(replace("/app"));
      })
      .catch(error => {
        dispatch(endLogin(false));
        alerts.danger(error.message);
      });



  };

  failureResponse = (response) => {
    console.log("Fallo Google");
    window.Android.showToast("FALLO GOOGLE")
    console.log(response);


  };


  
  onFailure = (error) => {
    alert(error);
  };
  render() {
    const { username, password } = this.state;
    const { loading, ...props } = this.props;
    return (
      <FormPanel {...props} title="Autogestión Cotel">
        <LoadableView loading={loading}>
          <div className="card">
            <div className="card-content">
              <form
                method="POST"
                onSubmit={this.onSubmit}
                autoComplete="off"
                ref={this.formRef}
              >
                <Field
                  label="Nombre de Usuario"
                  icon={faUser}
                  name="username"
                  type="text"
                  onChange={this.onUsernameChange}
                  value={username}
                  autoComplete="off"
                />
                <PasswordField
                  label="Contraseña"
                  icon={faKey}
                  name="password"
                  onChange={this.onPasswordChange}
                  value={password}
                  autoComplete="off"
                />
                <SubmitButton label="Iniciar sesión" />
                <p className="has-small-margin"> También puede iniciar sesión con:</p>
                <div className="is-flex-centered has-small-margin is-hidden-mobile">

                  <FacebookLogin
                    appId="1568845679906197"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={this.facebookResponse}
                    cssClass="button is-info is-medium has-small-margin"
                    icon={<FontAwesomeIcon icon={faFacebook} />}
                    textButton="&nbsp;&nbsp;Facebook"
                  />
                
                  <GoogleLogin
                    clientId="313402691562-5h58i71isdr3q7tk916kiltjrsa3b5n8.apps.googleusercontent.com"
                    buttonText="GOOGLE"
                    onSuccess={this.googleResponse}
                    onFailure={this.failureResponse}
                    render={renderProps => (
                      <button onClick={renderProps.onClick} className="button is-danger is-medium has-small-margin"> <FontAwesomeIcon icon={faGoogle} /> &nbsp; Google</button>
                    )}
                    /> 
                </div>

                <div className="is-flex-centered is-flex-vertical has-small-margin is-hidden-tablet">

                  <FacebookLogin
                    appId="1568845679906197"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={this.facebookResponse}
                    cssClass="button is-info is-medium has-small-margin"
                    icon={<FontAwesomeIcon icon={faFacebook} />}
                    textButton="&nbsp;&nbsp;Facebook"
                  />
              
                  <GoogleLogin
                    clientId="313402691562-5h58i71isdr3q7tk916kiltjrsa3b5n8.apps.googleusercontent.com"
                    buttonText="GOOGLE"
                    onSuccess={this.googleResponse}
                    onFailure={this.failureResponse}
                    render={renderProps => (
                      <button onClick={renderProps.onClick} className="button is-danger is-medium has-small-margin"> <FontAwesomeIcon icon={faGoogle} /> &nbsp; Google</button>
                    )}
                  />
                </div>


                <hr />
                <div className="is-hidden-mobile">
                  <div className="is-flex-centered is-flex-vertical">
                    <p>¿No posee una cuenta de usuario?</p>
                    <p> Imprima su cupon de pago</p>
                  </div>
                  <div className="control is-flex-centered" style={{ marginTop: "15px" }}>

                    <Link
                      to={`/app/cupones`}
                      className="button is-danger is-outlined is-medium"
                    >
                      Imprimir Cupon
                </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </LoadableView>
      </FormPanel>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.account.get("loading")

  };
}

export default withAlerts(connect(mapStateToProps)(AccountLoginPage));
