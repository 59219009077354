import React, { Component } from "react";
import { connect } from "react-redux";

import RequireLoadPage from "../components/RequireLoadPage";

import ServiceList from "../components/ServiceList";
import NoServices from "../components/NoServices";

import { startLoad, endLoadFail, endLoadSuccess } from "../reducers/services";

import Server from "../utils/server";

class ListServicesPage extends Component {
  componentDidMount() {
    const { dispatch, token } = this.props;
    dispatch(startLoad());
    Server.loadServices(token)
      .then(items => {
        setTimeout(() => dispatch(endLoadSuccess(items)), 1000);
      })
      .catch(e => {
        console.error(e);
        setTimeout(() => dispatch(endLoadFail()), 1000);
      });
  }

  render() {
    const { loading, items } = this.props;
    return (
      <RequireLoadPage loading={loading}>
        {items.length > 0 ? <ServiceList items={items} /> : <NoServices />}
      </RequireLoadPage>
    );
  }
}

function mapStateToProps(state) {
  const result = state.services.toJS();
  result.token = state.account.get("profile").get("token");
  return result;
}

export default connect(mapStateToProps)(ListServicesPage);
