import React, { Component } from "react";
import { connect } from "react-redux";

import RequireLoadPage from "../components/RequireLoadPage";

import ReclaimHistoryList from "../components/ReclaimHistoryList";
import NoHistory from "../components/NoHistory";

import { startLoad, endLoadFail, endLoadSuccess } from "../reducers/reclaims";

import Server from "../utils/server";

class ReclaimHistoryPage extends Component {
  componentDidMount() {
    const {name}= this.props.match.params;
    const { dispatch, token } = this.props;
    dispatch(startLoad());
    Server.loadHistorialReclaims(token,name)
      .then(items => {
        setTimeout(() => dispatch(endLoadSuccess(items)), 1000);
      })
      .catch(e => {
        console.error(e);
        setTimeout(() => dispatch(endLoadFail()), 1000);
      });
  }

  render() {
    const { loading, items } = this.props;
    
    return (
     
      <RequireLoadPage loading={loading}>
       
     
       <div className="box">
      
     
        {items.length > 0 ? <ReclaimHistoryList items={items} /> : <NoHistory />}
       
        </div>
        
                
      </RequireLoadPage>
    );
  }
}

function mapStateToProps(state) {
  const result = state.reclaims.toJS();
  result.token = state.account.get("profile").get("token");
  return result;
}

export default connect(mapStateToProps)(ReclaimHistoryPage);
