import React from "react";
import { Route } from "react-router";
import ErrorNotFoundPage from "./ErrorNotFoundPage";
import NavigationBarCoupon from "../components/NavigationBarCoupon";
import Sidebar from "../components/Sidebar";
import AccountMonitor from "../components/AccountMonitor";
import AnimatedSwitch from "../components/AnimatedSwitch";

import CouponsPage from "./CouponsPage";


const CouponsHomePage = () => {
  return (
    <div className="flex-container flex-expand is-flex-vertical absolute-fill">
      <NavigationBarCoupon />
      
      
        <div className="coupon-view">
          <AnimatedSwitch classNames="move-down" timeout={500}>
                       
            <Route component={CouponsPage} />
        
          </AnimatedSwitch>
        </div>
     
      <AccountMonitor />
    
      
    </div>
  );
};

export default CouponsHomePage;
