import React from "react";
import { Route } from "react-router";

//import PublicOnlyRoute from "./components/PublicOnlyRoute";
//import PrivateRoute from "./components/PrivateRoute";
import AnimatedSwitch from "./components/AnimatedSwitch";

import AccountLoginPage from "./pages/AccountLoginPage";

import ApplicationHomePage from "./pages/ApplicationHomePage";
import CouponsHomePage from "./pages/CouponsHomePage";
import ErrorNotFoundPage from "./pages/ErrorNotFoundPage";


function getKey(location) {
  if (location.pathname) {
    if (location.pathname.indexOf("app") !== -1) {
      return "app";
    }
    return "login";
  }
  return "";
}

const Routes = () => {
  return (
    <AnimatedSwitch classNames="move-down" timeout={500} customKey={getKey}>
      <Route redirect="/app" exact path="/" component={AccountLoginPage} />
      <Route path="/app/cupones" component={CouponsHomePage} />
      <Route redirect="/" path="/app" component={ApplicationHomePage} />
      <Route component={ErrorNotFoundPage} />
    </AnimatedSwitch>
  );
};

export default Routes;
