import React from "react";

const ErrorNotFoundPage = () => {
  return (
    <div className="loader-view">
      <h1>404</h1>
      <h2 className="has-text-centered">No se encontró la página solicitada</h2>
    </div>
  );
};

export default ErrorNotFoundPage;
