import React from "react";

import faThList from "@fortawesome/fontawesome-free-solid/faThList";
import faMoneyBill from "@fortawesome/fontawesome-free-solid/faMoneyBill";
import faMoneyCheckAlt from "@fortawesome/fontawesome-free-solid/faMoneyCheckAlt";
import SidebarLink from "./SidebarLink";

const Sidebar = () => {
  return (
    <nav className="panel flex-container is-flex-vertical auto-scroll has-background-white is-hidden-mobile">
      <div className="panel-body">
        <SidebarLink to="/app" title="Servicios" icon={faThList} onClick={null} />
       
       <SidebarLink to="/app/facturas" title="Facturas" icon={faMoneyBill} onClick={null} /> 
       <SidebarLink to="/app/deudas" title="Deudas" icon={faMoneyCheckAlt}  onClick={null}/> 
      </div>
    </nav>
  );
};

export default Sidebar;
