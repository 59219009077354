import React, { Component } from "react";
import { connect } from "react-redux";

import RequireLoadPage from "../components/RequireLoadPage";
import withAlerts from "../components/withAlerts"
import FacilitiesCard from "../components/FacilitiesCard";

import faTimesCircle from "@fortawesome/fontawesome-free-solid/faTimesCircle";
import { startLoad, endLoadFail, endLoadSuccess } from "../reducers/facilities"
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import Server from "../utils/server";



class RemoveFacilitiesPage extends Component {

        constructor(props){
            super(props);

           this.state={
                name: "",
                code:"",
                token:""
              }

              this.handleClick = this.handleClick.bind(this);
              
        }


    
       

  componentDidMount() {
    const {name,facilitie,code}= this.props.match.params;
    const { dispatch, token } = this.props;

    this.setState({
        name,
        token,      
        code
    })  
    dispatch(startLoad());
    dispatch(endLoadSuccess());
 
  }

    handleClick(event){
       
        const {alerts}= this.props;
        const {name,token,code} = this.state;

        Server.removeFacilitie(token,name,code)
        .then(mensaje => {
  
         console.log(mensaje);
         alerts.success(mensaje);
         this.props.history.push(`/app/facilidades/ver/inactivas/${name}`);
        
        })
        .catch(error => {
          
        });

        
          
    }

  
  render() {
    const { loading, items } = this.props;
    const {name,facilitie}= this.props.match.params;
    
    
    return (
     
      <RequireLoadPage loading={loading}>

        
       <div className="box">
      


      <p className="subtitle is-2 has-text-centered">Linea: {name}</p>
        <p className="subtitle is-3 has-text-centered">Se desactivará el siguiente servicio:</p>
        <p className="title is-3 has-text-centered">{facilitie}</p>

          <div className="content has-text-centered">
     <button className="button is-danger is-outlined is-medium "  onClick={this.handleClick}> <FontAwesomeIcon icon={faTimesCircle} /> &nbsp; Desactivar Servicio</button>
     </div>
     </div>
      </RequireLoadPage>
    );
  }
}

function mapStateToProps(state) {
  const result = state.facilities.toJS();
  result.token = state.account.get("profile").get("token");
  return result;
}

export default withAlerts(connect(mapStateToProps)(RemoveFacilitiesPage));
