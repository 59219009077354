import React, { Component } from "react";
import { connect } from "react-redux";
import { replace, goBack } from "react-router-redux";

import classNames from "classnames";

import { logOut } from "../reducers/account";

import NavigationBurger from "./NavigationBurger";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import withAlerts from "./withAlerts";
import SidebarLink from "./SidebarLink";
import faThList from "@fortawesome/fontawesome-free-solid/faThList";
import faArrowLeft from "@fortawesome/fontawesome-free-solid/faArrowLeft";
import faMoneyBill from "@fortawesome/fontawesome-free-solid/faMoneyBill";
import faMoneyCheckAlt from "@fortawesome/fontawesome-free-solid/faMoneyCheckAlt";

class NavigationBar extends Component {
  state = {
    active: false
  };

  toggleMenu = event => {

    this.setState({ active: !this.state.active });
  };

  onClose = event => {
    event.preventDefault();
    try{
      window.Android.closeSession();
    }catch(error){
      console.log(error);
    }
    try {
     
      window.Android.deletePDFbills();
  
    } catch (error)
    {
      console.log(error);
    }
    
    const { dispatch, alerts=null, profile } = this.props;
    //alerts.info(`¡Hasta pronto, ${profile.name}!`);
    dispatch(replace("/"));
   
    setTimeout(() => {
      dispatch(logOut());
    }, 500);

  };

  onBack = event => {
  
    const { dispatch, alerts=null, profile } = this.props;
    dispatch(goBack());
  };


  render() {
    const { profile } = this.props;
    const { active } = this.state;
    const menuClasses = classNames("navbar-menu", { "is-active": active });
    return (
      <nav className="navbar is-dark">
        <div className="navbar-brand">
        <a className="navbar-item back-button" onClick={this.onBack}> <FontAwesomeIcon icon={faArrowLeft} />  <p className="hidden-mobile"> &nbsp; &nbsp; Atrás</p></a>
       
        
          <a className="navbar-item is-hidden-mobile">Autogestión</a>
          <a className="navbar-item"><p className="name-wrap">{profile ? profile.name : ""}</p></a>
          <NavigationBurger onClick={this.toggleMenu} active={active} />
         
          
        
        </div>
    
        <div className={menuClasses}>

        
          <div className="navbar-end">
          <div className="is-hidden-tablet is-hidden-desktop">
          <SidebarLink to="/app" title="Servicios" icon={faThList} onClick={this.toggleMenu} />
       
       <SidebarLink to="/app/facturas" title="Facturas" icon={faMoneyBill} onClick={this.toggleMenu} /> 
          <SidebarLink to="/app/deudas" title="Deudas" icon={faMoneyCheckAlt}  onClick={this.toggleMenu}/> 
          </div>
         
            <a className="navbar-item" onClick={this.onClose}>
              Cerrar sesión
            </a>
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.account.get("profile").toJS()
  };
}

export default (connect(mapStateToProps)(NavigationBar));
