import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import faEye from "@fortawesome/fontawesome-free-solid/faEye";
import RequireLoadPage from "../components/RequireLoadPage";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import ReclaimList from "../components/ReclaimList";
import NoReclaims from "../components/NoReclaims";

import { startLoad, endLoadFail, endLoadSuccess } from "../reducers/reclaims";

import Server from "../utils/server";

class ReclaimsPage extends Component {
  componentDidMount() {
    const {name}= this.props.match.params;
    const { dispatch, token } = this.props;
    dispatch(startLoad());
    Server.loadReclaims(token,name)
      .then(items => {
        setTimeout(() => dispatch(endLoadSuccess(items)), 1000);
      })
      .catch(e => {
        console.error(e);
        setTimeout(() => dispatch(endLoadFail()), 1000);
      });
  }

  render() {
    const { loading, items } = this.props;
    const {name}= this.props.match.params;
    return (
     
      <RequireLoadPage loading={loading}>
       

        
        <div className="card has-small-margin">
      <div className="card-content">

        {items.length > 0 ? <ReclaimList items={items} /> : <NoReclaims />}
      
      <div className="is-flex-container is-flex-centered">
      <Link
                  to={`/app/reclamos/ver/historial/${name}`}
                  className="button is-info is-outlined is-medium"
                >
                  <FontAwesomeIcon icon={faEye} /> &nbsp; Ver Historial Reclamos
                </Link>
          
          
                </div>
          
        </div>
        </div>
                
      </RequireLoadPage>
    );
  }
}

function mapStateToProps(state) {
  const result = state.reclaims.toJS();
  result.token = state.account.get("profile").get("token");
  return result;
}

export default connect(mapStateToProps)(ReclaimsPage);
