import React from "react";
import { withRouter } from "react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Switch } from "react-router";

const AnimatedSwitch = withRouter(
  ({ location, children, classNames, timeout, customKey }) => {
    const key = customKey ? customKey(location) : location.pathname;
    return (
      <TransitionGroup
        key="transition-group"
        className="flex-container flex-expand is-relative"
      >
        <CSSTransition key={key} classNames={classNames} timeout={timeout}>
          <Switch location={location} key={`${key}-switch`}>
            {children}
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    );
  }
);

export default AnimatedSwitch;
