import React from "react";
import classNames from "classnames";
const NavigationBurger = ({ onClick,active }) => {
  const burgerClasses = classNames("navbar-burger", { "is-active": active },);
  return (
    <a
      role="button"
      className= {burgerClasses}
      aria-label="menu"
      aria-expanded="false"
      onClick={onClick}
    >
      <span aria-hidden="true" />
      <span aria-hidden="true" />
      <span aria-hidden="true" />
    </a>
  );
};

export default NavigationBurger;
