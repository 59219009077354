import I from "immutable";

const DEFAULT_STATE = I.fromJS({
  profile: { name: "", id: "" },
  loading: false
});

const LOGOUT = "app/account/logOut";
const KEEP_ALIVE = "app/account/keepAlive";

const START_LOGIN = "app/account/startLogin";
const END_LOGIN_SUCCESS = "app/account/endLoginSuccess";
const END_LOGIN_FAIL = "app/account/endLoginFail";

const ACTIONS = {
  [LOGOUT](state, payload) {
    return state.set("profile", I.Map({ name: "", id: "" }));
  },
  [START_LOGIN](state, payload) {
    return state.set("loading", true);
  },
  [END_LOGIN_FAIL](state, payload) {
    return state.merge({
      profile: { name: "", id: "" },
      loading: false
    });
  },
  [END_LOGIN_SUCCESS](state, { profile }) {
    return state.merge({
      profile,
      loading: false
    });
  }
};

function reducer(state = DEFAULT_STATE, action = {}) {
  const callback = ACTIONS[action.type];
  if (typeof callback === "function") {
    return callback(state, action.payload);
  }
  return state;
}

function logOut() {
  return {
    type: LOGOUT,
    payload: {}
  };
}

function keepAlive() {
  return {
    type: KEEP_ALIVE,
    payload: {}
  };
}

function startLogin() {
  return {
    type: START_LOGIN,
    payload: {}
  };
}

function endLogin(success = false, profile = null) {
  return {
    type: success ? END_LOGIN_SUCCESS : END_LOGIN_FAIL,
    payload: {
      profile
    }
  };
}

export { LOGOUT, KEEP_ALIVE, START_LOGIN, END_LOGIN_FAIL, END_LOGIN_SUCCESS };

export { logOut, keepAlive, startLogin, endLogin };

export default reducer;
