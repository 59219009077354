import React,{Component} from "react";
import  FontAwesomeIcon  from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import faCheck from "@fortawesome/fontawesome-free-solid/faCheck";
import faInfo from "@fortawesome/fontawesome-free-solid/faInfo";
import classNames from "classnames";


class DisabledFacilitiesCard extends Component {
 
  state = {
    open: false,
    loading: true
  };

  toggleModal = () => {
    const { url } = this.props;
   
    this.setState({ open: !this.state.open });
 
  };

  render(){
    const { open } = this.state;
    const{items,name}= this.props;
    const classes = classNames("modal", {
      "is-active": open
    });
    
    return(
    <React.Fragment>
    <p className="title is-size-4-mobile has-text-centered">Servicios Adicionales Inactivos</p>
    <p className="title is-size-4-mobile has-text-centered">{name}</p>
      <div>
    
      <p className="subtitle is-size-4-mobile has-text-centered">Activar alguno de estos servicios puede generar un costo adicional, el mismo se encuentra detallado en la tabla.</p>
      <div className="has-text-centered">
      <button
          type="button"
          className="button is-medium is-link is-outlined"
          onClick={this.toggleModal}
        >
        <FontAwesomeIcon icon={faInfo} /> &nbsp; Ver Info. Bloqueos
        </button>
        
        </div>
        <br/>
      <div className={classes}>
          <div className="modal-background" />
          <div className="modal-content is-expanded-iframe">
          <div className="box">
          <div className="table-wrapper">
        <table className="table  is-bordered is-striped">
        <thead>
        <tr>
          <th>Nombre</th>
          <th>¿Qué bloquea?</th>
         
          
        </tr>
      </thead>
      <tbody>
        <tr >
          <td  style={{width:"30%"}} > BLOQUEO TIPO 1 </td>
          <td> Emergencias - 0800 - DDN - DDI - 000 - 19 -  0600 - Local/0810 - CPP (15) - 0810-Cotel</td>
        </tr>
        
        <tr>
          <td> BLOQUEO TIPO 2 </td>
          <td>  DDN  -  DDI  -  000  -  19  -  0600  -  CPP(15)  -  0810-Cotel </td>
        </tr>
        <tr>
          <td> BLOQUEO TIPO 3 </td>
          <td> DDI  -  000  -  19  -  0600  -  0810-Cotel  </td>
        </tr>
        <tr>
          <td> BLOQUEO TIPO 4 </td>
          <td> DDI - 000 - 0600 - CPP (15) - 0810-Cotel </td>
        </tr>
        <tr>
          <td> BLOQUEO TIPO 5 </td>
          <td> 000 - 19 -  0600 - 0810-Cotel</td>
        </tr>

      </tbody>
        </table>
        </div>
        </div>
            {/*loading && <LoadingView absolute />*/}
          </div>

         
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={this.toggleModal}
          />
        </div>
        <div id="responsive-table">
      <table className="table  is-bordered is-striped is-hoverable">
    
      <thead>
        <tr>
          <th>Facilidad</th>
          <th>Tipo de Cobro</th>
          <th>Importe</th>
          <th>Acción</th>
          
        </tr>
      </thead>
      <tbody>
      {items.map((facilidad,i) => {  
    return(
            
            <tr key={'actfac-'+i}>

            
              <td data-title="Facilidad">{facilidad[1]} </td>
              <td data-title="Tipo de Cobro">{facilidad[2]}</td>
              <td data-title="Importe">$ {Number(facilidad[3]).toFixed(2)}</td>
              <td data-title="Acción">     
                {
                   facilidad[4] == "" || !facilidad[4] ? ( 
                
                     
              <Link
                  to={`/app/facilidades/agregar/${name}/${facilidad[0]}/${facilidad[1]}/nobloqueo`}
                  className="button is-success is-outlined is-medium"
                >
                  <FontAwesomeIcon icon={faCheck} /> &nbsp; Activar
                </Link>
                   )
                   
                   :
                   (

                   <Link
                   to={`/app/facilidades/agregar/${name}/${facilidad[0]}/${facilidad[1]}/${facilidad[4]}`}
                   className="button is-success is-outlined is-medium"
                 >
                   <FontAwesomeIcon icon={faCheck} /> &nbsp; Activar
                 </Link>
                   )
                  }
              </td>
              
            </tr>
          
        );
      })} 
      </tbody>
     
      
    </table>
    </div>
    </div>
 


  </React.Fragment>
    
    );
}
}

export default DisabledFacilitiesCard;
