import React, { Component } from "react";
import { connect } from "react-redux";
import Server from "../utils/server";
import RequireLoadPage from "../components/RequireLoadPage";

import NoDebts  from '../components/NoDebts';
import { startLoad, endLoadFail, endLoadSuccess } from "../reducers/debts";
import DebtList from "../components/DebtList";
class DebtsPage extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const { dispatch, token } = this.props;
    console.log( "TOKENNN " + token)
    dispatch(startLoad());
    Server.loadDebts(token)
      .then(items => {
        setTimeout(() => dispatch(endLoadSuccess(items)), 1000);
      })
      .catch(e => {
        console.error(e);
        setTimeout(() => dispatch(endLoadFail()), 1000);
      });
  }

  render() {
    const { loading, items } = this.props;
    return (
      <RequireLoadPage loading={loading}>

         
       <div className="card has-small-margin">
      <div className="card-content">
        {items.length > 0 ? <DebtList items={items} /> : <NoDebts />}

        </div>
        </div>
      </RequireLoadPage>
    );
  }
}

function mapStateToProps(state) {
  const result = state.debts.toJS();
  result.token = state.account.get("profile").get("token");
  return result;
}

export default connect(mapStateToProps)(DebtsPage);
