import React from "react";

class NoDebts extends React.Component  {
   
    render (){
        
         return(
        <React.Fragment>
       <p className="title is-size-4-mobile has-text-centered">Ninguno de sus servicios presenta deuda en este momento.</p>
        
        </React.Fragment>
         );
};
}
export default NoDebts;