//const BASE_URL = new URL(window.location.href);

//const API_URL = `${BASE_URL.protocol}//${BASE_URL.hostname}:8080/api/v1`;

const API_URL = 'https://kioscoapi.cotel.com.ar/api/v1';
//const API_URL = 'http://localhost:8080/api/v1';
function entry(path) {
  return `${API_URL}${path}`;
}

async function logIn(username, password) {
  let response;
  try {
    response = await fetch(entry("/account/login"), {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        username,
        password
      })
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
    return json.profile;
  }
  throw new Error(json.error);
}

async function loginFacebook(data,isNewSession) {
  let response;
  let body;
  if(isNewSession){
    body = JSON.stringify({
      facebookResponse:data
    });
  } else {
    body = JSON.stringify({
      sessionId:data
    });
  }
  try {
    response = await fetch(entry("/account/login-facebook"), {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
    return json.profile;
  }
  throw new Error(json.error);
}


async function loginGoogle(data,isNewSession) {
  let response;
  let body;
  if(isNewSession){
    body = JSON.stringify({
      googleResponse:data
    });
  } else {
    body = JSON.stringify({
      sessionId:data
    });
  }
  try {
    response = await fetch(entry("/account/login-google"), {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
    return json.profile;
  }
  throw new Error(json.error);
}



async function loadServices(token) {
  let response;
  try {
    response = await fetch(entry("/services"), {
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: "GET"
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
    return json.items;
  }
  throw new Error(json.error);
}

async function loadBills(token) {
  let response;
  try {
    response = await fetch(entry("/bills"), {
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: "GET"
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
    return json.items;
  }
  throw new Error(json.error);
}



async function loadDebts(token) {
  let response;
  try {
    response = await fetch(entry("/debt"), {
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: "GET"
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
    return json.items;
  }
  throw new Error(json.error);
}

async function loadReclaims(token,name) {
  let response;
  try {

    response = await fetch(entry(`/reclaims/${name}`), {
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: "GET"
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
  return json.items;
  }
  throw new Error(json.error);
}



async function loadHistorialReclaims(token,name) {
  let response;
  try {

    response = await fetch(entry(`/reclaims/historial/${name}`), {
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: "GET"
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
  return json.items;
  }
  throw new Error(json.error);
}

async function saveReclaims(token,name,selectedOption,telefono,hora1,hora2) {
  let response;
  try {
    response = await fetch(entry("/reclaims/nuevo"), {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        name,
        value: selectedOption.value,
        problem: selectedOption.label,
        telefono,
        hora1,
        hora2
       


      })
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
  
   return json.mensaje;
  }
  throw new Error(json.error);
}



async function loadFacilities(token,name) {
  let response;
  try {

    response = await fetch(entry(`/facilities/activas/${name}`), {
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: "GET"
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
  return json.items;
  }
  throw new Error(json.error);
}

async function loadInactiveFacilities(token,name) {
  let response;
  try {

    response = await fetch(entry(`/facilities/inactivas/${name}`), {
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: "GET"
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
  return json.items;
  }
  throw new Error(json.items);
}



async function removeFacilitie(token,name,code) {
  let response;
  try {
    response = await fetch(entry("/facilities/quitar"), {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        name,
        code


      })
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
  
   return json.mensaje;
  }
  throw new Error(json.error);
}

async function addFacilitie(token,name,code,block) {
  let response;
  try {
    response = await fetch(entry("/facilities/agregar"), {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        name,
        code,
        block


      })
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
  
   return json.mensaje;
  }
  throw new Error(json.mensaje);
}


async function loadConsumptions(token) {
  let response;
  try {
    response = await fetch(entry("/consumptions"), {
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: "GET"
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
    return json.items;
  }
  throw new Error(json.error);
}

async function loadCoupons(user) {
  let response;
  try {

    response = await fetch(entry(`/coupons/${user}`), {
      
      method: "GET"
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json.ok) {
  return json.items;
  }
  throw new Error(json.error);
}

async function printBill(url,printer_id) {
  let response;
  try {
    response = await fetch(entry("/coupons/print"), {
      headers: {
        
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        url,
        printer_id


      })
    });
  } catch (e) {
    console.error(e);
    throw new Error("Fallo la conexión con el servidor");
  }
  const json = await response.json();
  if (json) {
  
   return json;
  }
  throw new Error("Fallo la conexion con el servidor");
}




export default {
  logIn,
  loadServices,
  loadBills,
  loadDebts,
  loadReclaims,
  loadHistorialReclaims,
  saveReclaims,
  loadFacilities,
  loadInactiveFacilities,
  removeFacilitie,
  addFacilitie,
  loadConsumptions,
  loadCoupons,
  printBill,
  loginFacebook,
  loginGoogle
 
};
