import React from "react";

const NoHistory = () => {
  return (
    <React.Fragment>
      <p className="title is-size-4-mobile has-text-centered">No hay reclamos asignados en este servicio.</p>
    </React.Fragment>
  );
};

export default NoHistory;