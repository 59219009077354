import React from "react";

const FormPanel = ({ title, children }) => {
  return (
    <div className="flex-container flex-expand is-flex-vertical absolute-fill main-background">
      <div className="container">
        <section className="section is-flex-centered is-flex-vertical">
          <h2 className="title has-text-white  is-size-1-desktop is-size-3-mobile">{title}</h2>
          {children}
        </section>
      </div>
    </div>
  );
};

export default FormPanel;
