import React from "react";

const SubmitButton = ({ label }) => {
  return (
    <div className="control is-flex-centered">
      <button type="submit" className="button is-primary is-outlined is-large">
        {label}
      </button>
    </div>
  );
};

export default SubmitButton;
