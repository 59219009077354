import React from "react";
import classNames from "classnames";

import MoveDownTransition from "./MoveDownTransition";

const LoadingView = ({ absolute, ...props }) => {
  const classes = classNames("loader-view", {
    "absolute-fill": absolute,
    "max-z": absolute
  });
  return (
    <MoveDownTransition {...props}>
      <div className={classes}>
        <div className="loader" />
      </div>
    </MoveDownTransition>
  );
};

export default LoadingView;
