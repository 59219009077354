import React from "react";

import ServiceCard from "./ServiceCard";

const ServiceList = ({ items }) => {
  return (
    <React.Fragment>
      <h1 class ="is-hidden-tablet title is-3 has-text-white"> Servicios Asociados</h1>
      {items.map(service => (
        <ServiceCard key={`${service.clientId}-${service.name}`} {...service} />
      ))}
    </React.Fragment>
  );
};

export default ServiceList;
