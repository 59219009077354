import React, { Component } from "react";
import { connect } from "react-redux";

import RequireLoadPage from "../components/RequireLoadPage";

import DisabledFacilitiesCard from "../components/DisabledFacilitiesCard";


import { startLoad, endLoadFail, endLoadSuccess } from "../reducers/disabledFacilities";

import Server from "../utils/server";

class DisabledFacilitiesPage extends Component {
  
  componentDidMount() {
    const {name}= this.props.match.params;
    const { dispatch, token } = this.props;
    dispatch(startLoad());
    Server.loadInactiveFacilities(token,name)
      .then(items => {
         
        setTimeout(() => dispatch(endLoadSuccess(items)), 1000);
      })
      .catch(e => {
        console.error(e);

        setTimeout(() => dispatch(endLoadFail()), 1000);
      });
  }

  render() {
    const { loading, items } = this.props;
    const {name}= this.props.match.params;
    return (
     
      <RequireLoadPage loading={loading}>
       
     
       <div className="box">
      
     
         <DisabledFacilitiesCard items={items} name={name} /> 
       
        </div>
        
                
      </RequireLoadPage>
    );
  }
}

function mapStateToProps(state) {
  const result = state.disabledFacilities.toJS();
  result.token = state.account.get("profile").get("token");
  return result;
}

export default connect(mapStateToProps)(DisabledFacilitiesPage);
