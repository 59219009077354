import React, { Component } from "react";

import BillDialog from "./BillDialog";

class BillTable extends Component{
  state = {   
    loading: false
  };

  setLoading = (bool) => {
    this.setState({loading:bool})
  }

  render(){

    const {bills} = this.props;
    const {loading} = this.state;
    if(loading){
      return ( <React.Fragment>
        <div className= "card">
        <p className="title is-3">Imprimiendo factura.. Espere unos segundos </p>
        </div>
        </React.Fragment>
        
        );
    }else{
  return (
    <React.Fragment>
      <h3 className="title is-size-2-tablet is-size-3-mobile has-text-white">Facturas encontradas</h3>
      <div id="responsive-table">
      <table className="table">
        <thead>
          <tr>
            <th>Cuenta</th>
            <th>Mes Actual</th>
            <th>Mes Anterior</th>
          </tr>
        </thead>
        <tbody>
          {bills.map(bill => {
            return (
              <tr key={bill.clientId}>
                <td data-title="N° Cuenta">{bill.clientId}</td>
                <td data-title="Mes Actual">
                  {bill.bills.current ? (
                    <BillDialog url={bill.bills.current} clientId={bill.clientId} setLoading={this.setLoading}/>
                  ) : (
                    <span className="has-text-light">( No disponible )</span>
                  )}
                </td>
                <td data-title="Mes Anterior">
                  {bill.bills.previous ? (
                    <BillDialog url={bill.bills.previous} clientId={bill.clientId} setLoading={this.setLoading} />
                  ) : (
                    <span className="has-text-light">( No disponible )</span>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="is-hidden-mobile">
          <tr>
            <th>Cuenta</th>
            <th>Mes Actual</th>
            <th>Mes Anterior</th>
          </tr>
        </tfoot>
      </table>
      </div>
    </React.Fragment>
  );
}
}
};


export default BillTable;
