import React from "react";




const ReclaimHistoryCard= ({ items }) => {
  
  return (
    <React.Fragment>
      <div>
     <p className="title is-size-4-mobile has-text-centered">Historial de Reclamos</p>
     <p className="title is-size-4-mobile has-text-centered">{items[0][1]}</p>
     <div id="responsive-table">
           <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
    
      <thead>
        <tr>
          <th>Nº de pedido</th>
          <th>Fecha de pedido</th>
          <th>Estado</th>
          <th>Descripción</th>
          <th>Fecha de asignación</th>
          <th>Fecha de cierre</th>
        </tr>
      </thead>
      <tbody>
      {items.map((reclaim,i) => {  
    return(
            
            <tr key={'rec-'+i}>
              <td data-title="Nº de Reclamo">{reclaim[0]}</td>
              <td data-title="Fecha de Pedido">            
              {reclaim[2]}
              </td>
              <td data-title="Estado">
              {reclaim[3]}
              </td>
              <td data-title="Descripción">
              {reclaim[4]}
              </td>
              <td data-title="Fecha de asign.">
              {reclaim[5]}
              </td>
              <td data-title="Fecha de cierre">
              {reclaim[6]}
              </td>
            </tr>
          
        );
      })} 
      </tbody>
     
      
    </table>
    </div>
    </div>

  </React.Fragment>
    
  );
};

export default ReclaimHistoryCard;
