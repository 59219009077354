import React from "react";

import { Link } from "react-router-dom";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";

import faLock from "@fortawesome/fontawesome-free-solid/faLock";
import faPhone from "@fortawesome/fontawesome-free-solid/faPhone";
import faPhoneVolume from "@fortawesome/fontawesome-free-solid/faPhoneVolume";
import faGlobe from "@fortawesome/fontawesome-free-solid/faGlobe";
import faTachometer from "@fortawesome/fontawesome-free-solid/faTachometerAlt";
import faPlug from "@fortawesome/fontawesome-free-solid/faPlug";
import faPlus from "@fortawesome/fontawesome-free-solid/faPlus";
import faMinus from "@fortawesome/fontawesome-free-solid/faMinus";
import faEye from "@fortawesome/fontawesome-free-solid/faEye";
import ServiceCardMobile from "./ServiceCardMobile";
function findIcon(name, rate) {
  if (rate && rate.toLowerCase().indexOf("par fisico") !== -1) {
    return faPlug;
  }
  if (rate && rate.toLowerCase().indexOf("par físico") !== -1) {
    return faPlug;
  }
  if (isNaN(name)) {
    if (name.indexOf("fo-") === 0) {
      return faTachometer;
    }
    return faGlobe;
  }
  if (name.startsWith('2255')) {
    return faPhone;
  }

  return faLock;
}


function validRate(name, rate) {
  if (rate.startsWith('Inter') && name.startsWith('fo-')) {
    return rate.substr(0, rate.indexOf('/'));
  }
  return rate;
}

const ServiceCard = ({ clientId, name, rate }) => {
  const icon = findIcon(name, rate);

  const serv = validRate(name, rate);

  return (
    <div className="card has-small-margin">
      <div className="card-content">
        <div className="media is-hidden-mobile">
          <div className="media-left">
            <FontAwesomeIcon icon={icon} />
          </div>
          <div className="media-content">

            
            <p className="title is-4">{name}</p>
            <p className="subtitle is-4">{rate}</p>
          </div>

          <div className="media-right">
            {icon === faPhone ? (
              <Link
                to={`/app/consumos/${name}`}
                className="button is-link is-outlined is-medium"
              >
                <FontAwesomeIcon icon={faPhoneVolume} /> &nbsp; Ver Consumo
         </Link>) : (<p>&nbsp;</p>)

            }
          </div>
        </div>
        <div className="content is-hidden-mobile">
          <hr />
          <div className="is-flex flex-justify-end">
            <div className="field is-grouped">
              {icon === faPhone ? (
    /*            
                <p className="control">
                  <Link
                    to={`/app/facilidades/ver/inactivas/${name}`}
                    className="button is-primary is-outlined is-medium"
                  >
                    <FontAwesomeIcon icon={faPlus} /> &nbsp; Activar
                    Servicios
                  </Link>
                  &nbsp;   &nbsp;
                  <Link
                    to={`/app/facilidades/ver/activas/${name}`}
                    className="button is-primary is-outlined is-medium"
                  >
                    <FontAwesomeIcon icon={faMinus} /> &nbsp; Desactivar
                    Servicios
                  </Link>
                </p>
*/null) : null}
              <p className="control">
                <Link
                  to={`/app/reclamos/ver/${name}`}
                  className="button is-info is-outlined is-medium"
                >
                  <FontAwesomeIcon icon={faEye} /> &nbsp; Ver Reclamos
                </Link>
              </p>
              <p className="control">
                <Link
                  to={`/app/reclamos/nuevo/${name}/${serv}`}
                  className="button is-danger is-outlined is-medium"
                >
                  <FontAwesomeIcon icon={faPlus} /> &nbsp; Realizar Reclamo
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="is-hidden-tablet is-flex is-flex-vertical">
          
                <ServiceCardMobile icon={icon} name={name} rate={rate} serv={serv} />
               
                </div>
      </div>
    </div>
  );
};

export default ServiceCard;
