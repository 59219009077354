import React, { Component } from "react";

import classNames from "classnames";

import LoadingView from "./LoadingView";


import { Link } from "react-router-dom";
import faPrint from "@fortawesome/fontawesome-free-solid/faPrint";
import Server from "../utils/server";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import PDFView from "./PDFView";
class BillDialog extends Component {
  state = {
    open: false,
    loading: true
  };

  toggleModal = () => {
    const { url } = this.props;

    this.setState({ open: !this.state.open });

  };



  printBill = () => {
    //this.setState({ open: !this.state.open });
    const { url, clientId, setLoading } = this.props;
    print(url,setLoading);
    // imprimir(url, clientId);

  };

  viewBill = () => {
    //this.setState({ open: !this.state.open });
    const { url, clientId } = this.props;
    verPdf(url, clientId);

  };

  onLoad = () => {
    this.setState({ loading: false });
  };



  render() {
    const { open, loading } = this.state;
    const { url } = this.props;
    const classes = classNames("modal", {
      "is-active": open
    });
    return (
      <React.Fragment>
        <button
          type="button"
          className="button is-medium is-link is-outlined"
          onClick={this.viewBill}
        >
          Ver
        </button>
        &nbsp;
        <button
          type="button"
          className="button is-medium is-danger is-outlined is-hidden-mobile"
          onClick={this.printBill}
        >
          Imprimir


      </button>
 {/*
        <div className={classes}>
          <div className="modal-background" />
          <div className="modal-content no-overflow is-expanded-iframe">
            <PDFView url={url} />

            {//loading && <LoadingView absolute />
            }
          </div>


          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={this.toggleModal}
          />
        </div> */}
        {/*  
        <div className={classes}>
          <div className="modal-background" />
          <div className="modal-card no-overflow is-expanded-iframe is-relative" >
           


              <PDFView url={url} />
                      
           

            <button
              className="modal-close is-large"
              aria-label="close"
              onClick={this.toggleModal}
            />

              
           
            <footer className="modal-card-foot is-flex-centered" style={{ background:"#FF000000"}}>
          */}
        {
           /*
               <button
className="button is-info is-medium"
onClick={this.printBill}
><FontAwesomeIcon icon={faPrint} /> &nbsp; Imprimir Factura </button>

            </footer>
            
          </div>
        </div>
        */}
      </React.Fragment>
    );
  }
}

async function verPdf(url, clientId) {


  const pdfName = url.slice(url.lastIndexOf(clientId), url.length).replace(/%20/gi, "_");
  
  try {
    window.Android.viewPDFbills(String(pdfName));
  } catch (error) {
    console.log(error);
  }
}
async function imprimir(url, clientId) {
  const pdfName = url.slice(url.lastIndexOf(clientId), url.length).replace(/%20/gi, "_");
  try {
    window.Android.printPDFbills(String(pdfName));
  } catch (error) {
    console.log(error);
  }
}



async function print(url,setLoading){

  try {
    const printerId = window.Android.printerId();
   
        setLoading(true);

        Server.printBill(url, printerId)
          .then(json => {
           
            if(json.ok == true){
              
              setTimeout(function() {
                setLoading(false);
             }, 5000);
             
            

           
            }else {
              window.Android.showToast(json.msg);
              setLoading(false);
      
            }
          

          }).catch(msg => {
            setLoading(false);
         
            

          })
      
     



  } catch (error) {
    console.log(error);
  }
 }


export default BillDialog;
