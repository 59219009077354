import React from "react";

const NoBills = () => {
  return (
    <React.Fragment>
      <h3>Ninguno de sus servicios contiene una factura de momento.</h3>
    </React.Fragment>
  );
};

export default NoBills;
