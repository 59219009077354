import React from "react";

import ReclaimCard from "./ReclaimCard";

const ReclaimList = ({ items }) => {
  return (
    <React.Fragment>
      
        <ReclaimCard items={items}/>
      
    </React.Fragment>
  );
};

export default ReclaimList;