import React from "react";

import ReclaimHistoryCard from "./ReclaimHistoryCard";

const ReclaimHistoryList = ({ items }) => {
  return (
    <React.Fragment>
      
        <ReclaimHistoryCard items={items}/>
      
    </React.Fragment>
  );
};

export default ReclaimHistoryList;