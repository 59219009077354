import React, { Component } from "react";
import { connect } from "react-redux";

import RequireLoadPage from "../components/RequireLoadPage";
import BillTable from "../components/BillTable";
import NoBills from "../components/NoBills";

import Server from "../utils/server";

import { startLoad, endLoadFail, endLoadSuccess } from "../reducers/bills";

class BillsPage extends Component {
  state = {
    loading: true
  };

 async componentDidMount() {
    const { dispatch, token } = this.props;


    dispatch(startLoad());
    Server.loadBills(token)
      .then(items => {
        
        let pdfNames = [];
        items.map(item=> {
          
          if(item.bills.current && item.bills.previous){
          
            const link1 =item.bills.current;
            const link2 =item.bills.previous;   

          const pdfName= link1.slice(link1.lastIndexOf(item.clientId),link1.length).replace(/%20/gi,"_");
          const pdfName2= link2.slice(link2.lastIndexOf(item.clientId),link2.length).replace(/%20/gi,"_");
         try{
          window.Android.downloadPDFbills(link1,pdfName);
          window.Android.downloadPDFbills(link2,pdfName2);
        } catch (error)
        {
          console.log(error);
        }

        }
        })
       
       
        setTimeout(() => dispatch(endLoadSuccess(items)), 1000);
      })
      .catch(e => {
        console.error(e);
        setTimeout(() => dispatch(endLoadFail()), 1000);
      });
  }

  render() {
    const { loading, items } = this.props;
    return (
      <RequireLoadPage loading={loading}>
        {items.length ? <BillTable bills={items} /> : <NoBills />}
      </RequireLoadPage>
    );
  }
}

function mapStateToProps(state) {
  const result = state.bills.toJS();
  result.token = state.account.get("profile").get("token");
  return result;
}

export default connect(mapStateToProps)(BillsPage);
