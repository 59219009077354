import React, { Component } from "react";
import { connect } from "react-redux";

import RequireLoadPage from "../components/RequireLoadPage";

import FacilitiesCard from "../components/FacilitiesCard";


import { startLoad, endLoadFail, endLoadSuccess } from "../reducers/facilities";

import Server from "../utils/server";

class FacilitiesPage extends Component {
  componentDidMount() {
    const {name}= this.props.match.params;
    const { dispatch, token } = this.props;
    dispatch(startLoad());
    Server.loadFacilities(token,name)
      .then(items => {
         
        setTimeout(() => dispatch(endLoadSuccess(items)), 1000);
      })
      .catch(e => {
        console.error(e);
        setTimeout(() => dispatch(endLoadFail()), 1000);
      });
  }

   render() {
    const { loading, items } = this.props;
    const {name}= this.props.match.params;
    return (
     
      <RequireLoadPage loading={loading}>
       
     
       <div className="box">
      
     
         <FacilitiesCard items={items} name={name} /> 
       
        </div>
         
      </RequireLoadPage>
    );
  }
}

function mapStateToProps(state) {
  const result = state.facilities.toJS();
  result.token = state.account.get("profile").get("token");
  return result;
}

export default connect(mapStateToProps)(FacilitiesPage);
