import React from "react";
import { actions as notifActions } from "redux-notifications";

import { connect } from "react-redux";

const { notifSend } = notifActions;

function mapStateToProps(state) {
  return {};
}

function withAlerts(Component) {
  const WithAlerts = ({ dispatch, ...props }) => {
    const alerts = {
      info(message, dismissAfter = 2000) {
        notifSend({
          message,
          kind: "info",
          dismissAfter
        })(dispatch);
      },
      success(message, dismissAfter = 2000) {
        notifSend({
          message,
          kind: "success",
          dismissAfter
        })(dispatch);
      },
      warning(message, dismissAfter = 2000) {
        notifSend({
          message,
          kind: "warning",
          dismissAfter
        })(dispatch);
      },
      danger(message, dismissAfter = 2000) {
        notifSend({
          message,
          kind: "danger",
          dismissAfter
        })(dispatch);
      }
    };
    return <Component {...props} alerts={alerts} />;
  };
  return connect(mapStateToProps)(WithAlerts);
}

export default withAlerts;
