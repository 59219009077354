import React, { Component } from "react";
import { connect } from "react-redux";
import Server from "../utils/server";
import RequireLoadPage from "../components/RequireLoadPage";

import NoDebts  from '../components/NoDebts';
import { startLoad, endLoadFail, endLoadSuccess } from "../reducers/consumptions";
import ConsumptionCard from "../components/ConsumptionCard";
class ConsumptionsPage extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const { dispatch, token } = this.props;
    dispatch(startLoad());
    Server.loadConsumptions(token)
      .then(items => {
        setTimeout(() => dispatch(endLoadSuccess(items)), 1000);
      })
      .catch(e => {
        console.error(e);
        setTimeout(() => dispatch(endLoadFail()), 1000);
      });
  }

  render() {
    const { loading, items } = this.props;
    const {name} = this.props.match.params;
    return (
      <RequireLoadPage loading={loading}>

         
       <div className="card has-small-margin">
      <div className="card-content">
        {items.length > 0 ? <ConsumptionCard items={items} name={name} /> : <NoDebts />}

        </div>
        </div>
      </RequireLoadPage>
    );
  }
}

function mapStateToProps(state) {
  const result = state.consumptions.toJS();
  result.token = state.account.get("profile").get("token");
  return result;
}

export default connect(mapStateToProps)(ConsumptionsPage);
