import React from "react";




const DebtCard= ({ items }) => {
  
  
    function total(items){
     var total=0;
      for(var i of items){
       var parcial= Number(i.debt);
        total+=parcial;
     }
     var resultado= trunc(total,2);
     return resultado;
    }


    function trunc (x, posiciones = 0) {
      var s = x.toString()
      var l = s.length
      var decimalLength = s.indexOf('.') + 1
      var numStr = s.substr(0, decimalLength + posiciones)
      return Number(numStr)
    }
    
  return (
   <React.Fragment>
     <h1 className="title is-size-3-mobile has-text-centered">
     Deudas Encontradas
      </h1>
      <div id="responsive-table">
<table className="table is-hoverable is-full-width">
    
    
    

    
    <thead>
      <tr>
        <th>Servicio</th>
        <th>Descripción</th>
        <th>Emisión</th>
        <th>Vencimiento</th>
        <th>Importe</th>
       
      </tr>
    </thead>
    <tbody>

           
           {items.map((debts,i) => {  
            
    return(
            
            <tr key={'deb-'+i}>
              <td data-title="Servicio">{debts.service}</td>
              <td data-title="Descripción">            
              {debts.description}
              </td>
              <td data-title="Emisión">
              {debts.date}
              </td>
              <td data-title="Vencimiento">
              {debts.expiration}
              </td>
              <td data-title="Importe" className="nowrap">
             $ {debts.debt}
              </td>
           

            </tr>
          
        );
      })} 
          
  
    </tbody>
    <tfoot>
                    <tr>
                        <th >Total:</th>
                        <th  className ="hidden-mobile" ></th>
                        <th  className ="hidden-mobile"></th>
                        <th  className ="hidden-mobile"></th>
               
                        <th className="nowrap">$ {total(items)}</th>
                        
                    </tr>
                </tfoot>
    
  </table>
  </div>
  </React.Fragment>
  );
};

export default DebtCard;
