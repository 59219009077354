import React from "react";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import faTimes from "@fortawesome/fontawesome-free-solid/faTimes";



const FacilitiesCard= ({ items,name }) => {
  
  return (
    <React.Fragment>
    <p className="title is-size-4-mobile has-text-centered">Servicios Adicionales Activos</p>
    <p className="subtitle is-size-4-mobile has-text-centered"> Linea {name}</p>
      <div className="content has-text-centered">
    
     
    
      <div id="responsive-table">
      <table className="table is-bordered is-striped is-narrow">
    
      <thead>
        <tr>
          <th>Facilidad</th>
          <th>Accion</th>
          
        </tr>
      </thead>
      <tbody>
      {items.map((facilidad,i) => {  
    return(
            
            <tr key={'fac-'+i}>
              <td data-title="Facilidad">{facilidad[1]}</td>
             
              <td data-title="Acción">     

                   {facilidad[2]===1 ? (     
              <Link
                  to={`/app/facilidades/quitar/${name}/${facilidad[0]}/${facilidad[1]}`}
                  className="button is-danger is-outlined is-medium"
                >
                  <FontAwesomeIcon icon={faTimes} /> &nbsp; Desactivar
                </Link>

                   ): <button className="button is-danger is-outlined is-medium" disabled >  <FontAwesomeIcon icon={faTimes} /> &nbsp; Desactivar </button>}    
              </td>
              
            </tr>
          
        );
      })} 
      </tbody>
     
      
    </table>
    </div>
    </div>
 


  </React.Fragment>
    
  );
};

export default FacilitiesCard;
