import React from "react";
import { Route } from "react-router";
import ErrorNotFoundPage from "./ErrorNotFoundPage";
import NavigationBar from "../components/NavigationBar";
import Sidebar from "../components/Sidebar";
import AccountMonitor from "../components/AccountMonitor";
import AnimatedSwitch from "../components/AnimatedSwitch";
import ListServicesPage from "./ListServicesPage";
import BillsPage from "./BillsPage";
import DebtsPage from "./DebtsPage";
import ReclaimHistoryPage from "./ReclaimHistoryPage";
import ReclaimsPage from "./ReclaimsPage";
import NewReclaimPage from "./NewReclaimPage";
import FacilitiesPage from "./FacilitiesPage";
import DisabledFacilitiesPage from "./DisabledFacilitiesPage";
import RemoveFacilitiesPage from "./RemoveFacilitiesPage";
import AddFacilitiePage from "./AddFacilitiePage";
import ConsumptionsPage from "./ConsumptionsPage"

const ApplicationHomePage = () => {
  return (
    <div className="flex-container flex-expand is-flex-vertical absolute-fill">
      <NavigationBar />
      <div className="flex-container flex-expand">
        <Sidebar />
        <div className="flex-container flex-expand main-background is-relative">
          <AnimatedSwitch classNames="move-down" timeout={500}>
            <Route exact path="/app" component={ListServicesPage} />
            <Route exact path="/app/facturas" component={BillsPage} />
            <Route exact path="/app/deudas" component={DebtsPage} />
            <Route exact path="/app/consumos/:name" component={ConsumptionsPage} />
            <Route exact path="/app/reclamos/ver/:name" component={ReclaimsPage} />
            <Route exact path="/app/reclamos/ver/historial/:name" component={ReclaimHistoryPage} />
            <Route exact path="/app/reclamos/nuevo/:name/:rate" component={NewReclaimPage} />
            <Route exact path="/app/facilidades/ver/activas/:name" component={FacilitiesPage} />
            <Route exact path="/app/facilidades/ver/inactivas/:name" component={DisabledFacilitiesPage} />
            <Route exact path="/app/facilidades/quitar/:name/:code/:facilitie" component={RemoveFacilitiesPage} />
            <Route exact path="/app/facilidades/agregar/:name/:code/:facilitie/:block" component={AddFacilitiePage} />
            <Route component={ErrorNotFoundPage} />
          </AnimatedSwitch>
        </div>
      </div>
      <AccountMonitor />
    </div>
  );
};

export default ApplicationHomePage;
