import React, { Component } from "react";
import { connect } from "react-redux";

import RequireLoadPage from "../components/RequireLoadPage";
import withAlerts from "../components/withAlerts"


import faTh from "@fortawesome/fontawesome-free-solid/faTh";
import faCheckCircle from "@fortawesome/fontawesome-free-solid/faCheckCircle";
import { startLoad, endLoadFail, endLoadSuccess } from "../reducers/facilities"
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import Server from "../utils/server";



class AddFacilitiesPage extends Component {

        constructor(props){
            super(props);

           this.state={
                name: "",
                code:"",
                block:"",
                token:""
              }

              this.handleClick = this.handleClick.bind(this);
              this.handleChange = this.handleChange.bind(this);
        }


    
       

  componentDidMount() {
    const {name,code,block}= this.props.match.params;
    const { dispatch, token } = this.props;

    this.setState({
        name,
        token,      
        code,
        block
    })  
    console.log('pepe');
    dispatch(startLoad());
    dispatch(endLoadSuccess())
    /*
    Server.loadFacilities(token,name)
      .then(items => {
         
        setTimeout(() => dispatch(endLoadSuccess(items)), 1000);
      })
      .catch(e => {
        console.error(e);
        setTimeout(() => dispatch(endLoadFail()), 1000);
      });
      */
  }

    handleClick(event){
       
        const {alerts}= this.props;
        const {name,token,code,block} = this.state;
        
        console.log(block);
        if(block!=="nobloqueo"){
            if(String(block).length <4 || String(block).length >4){
                alerts.danger('Debe ingresar un código de 4 dígitos');
                return;
            }
            
        }


        

        Server.addFacilitie(token,name,code,block)
        .then(mensaje => {
  
         console.log(mensaje);
         alerts.success(mensaje);
         this.props.history.push(`/app/facilidades/ver/activas/${name}`);
        
        })
        .catch(error => {
          console.log(error);
        });
    
        
          
    }

    handleChange(event){
        
        if (!/^[ 0-9]*$/i.test(event.target.value)) {
            event.target.value= event.target.value.replace(/[^ 0-9]+/ig,"");
        }
     this.setState({
         block:event.target.value
     })
    }

  
  render() {
    const { loading, items } = this.props;
    const {name,facilitie,block}= this.props.match.params;
    
    
    return (
     
      <RequireLoadPage loading={loading}>

        
       <div className="box">
      


      <p className="subtitle is-size-3-mobile  has-text-centered">Linea: {name}</p>
        <p className="subtitle is-size-4-mobile has-text-centered">Se activará el siguiente servicio:</p>
        <p className="title is-size-4-mobile has-text-centered">{facilitie}</p>
        { block!=="nobloqueo" ? ( 
           <div>
           <p className="subtitle is-size-4-mobile has-text-centered"> Ingrese un código de cuatro dígitos para activar el servicio desde su teléfono  </p>
            
<div className="is-flex-container">
<div className="is-flex-centered">

  <div className="control has-icons-left has-icons-right">
    <input className="input is-medium" type="text" placeholder="Código de bloqueo" onChange={this.handleChange}/>
    <span className="icon is-small is-left">
    <FontAwesomeIcon icon={faTh} />
    </span>
      </div>
      </div>
      </div>
      </div>
   ):(<div></div> ) }

        <p>&nbsp;</p>
          <div className="has-text-centered">
     <button className="button is-success is-outlined is-medium "  onClick={this.handleClick}> <FontAwesomeIcon icon={faCheckCircle} /> &nbsp; Activar Servicio</button>
     </div>
     
     </div>
     
      </RequireLoadPage>
    );
  }
}

function mapStateToProps(state) {
  const result = state.facilities.toJS();
  result.token = state.account.get("profile").get("token");
  return result;
}

export default withAlerts(connect(mapStateToProps)(AddFacilitiesPage));
