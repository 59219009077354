import React from "react";

import PDF from "react-pdf-js";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";

import faArrowRight from "@fortawesome/fontawesome-free-solid/faArrowRight";
import faArrowLeft from "@fortawesome/fontawesome-free-solid/faArrowLeft";

class PDFView extends React.Component {
  state = {};

  onDocumentComplete = pages => {
    this.setState({ page: 1, pages });
  };

  onPageComplete = page => {
    this.setState({ page });
  };

  handlePrevious = () => {
    this.setState({ page: this.state.page - 1 });
  };

  handleNext = () => {
    this.setState({ page: this.state.page + 1 });
  };

  renderPagination = (page, pages) => {
    let previousButton = (
      <a href="#" className="pagination-previous" onClick={this.handlePrevious}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </a>
    );
    if (page === 1) {
      previousButton = (
        <a href="#" className="pagination-previous is-current">
          <FontAwesomeIcon icon={faArrowLeft} />
        </a>
      );
    }
    let nextButton = (
      <a href="#" className="pagination-next" onClick={this.handleNext}>
        <FontAwesomeIcon icon={faArrowRight} />
      </a>
    );
    if (page === pages) {
      nextButton = (
        <a href="#" className="pagination-next is-current">
          <FontAwesomeIcon icon={faArrowRight} />
        </a>
      );
    }
    return (
      <nav className="pagination is-medium">
        {previousButton}
        {nextButton}
      </nav>
    );
  };

  render() {
    const { url, onLoad } = this.props;
    let pagination = null;
    if (this.state.pages) {
      pagination = this.renderPagination(this.state.page, this.state.pages);
    }
    return (
      <iframe
        className="flex-expand is-expanded-iframe"
        src={`https://drive.google.com/gview?embedded=true&url=${url}`}
      />
      /*
      <div>
          <PDF
            file={url}
            onDocumentComplete={this.onDocumentComplete}
            onPageComplete={this.onPageComplete}
            page={this.state.page}
          />
       
        <div className="main-background">{pagination}</div>
      </div>
      */
    );
  }
}

export default PDFView;
