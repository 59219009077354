import { Component } from "react";
import { connect } from "react-redux";
import { addTimeout, removeTimeout, WATCH_ALL } from "redux-timeout";
import { replace } from "react-router-redux";

import { logOut } from "../reducers/account";

import withAlerts from "./withAlerts";

const TIMEOUT = 10 * 60 * 1000;

class AccountMonitor extends Component {
  logoutAccount = () => {
    const { dispatch, profile, alerts } = this.props;
    if (profile) {
      try {
        window.Android.deletePDFbills();
      } catch (error) {
        console.log(error);
      }
      alerts.danger("Se ha cerrado la sesión por falta de actividad.")
      dispatch(replace("/"));
      setTimeout(() => {
        dispatch(logOut());
      }, 500);
    }
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(addTimeout(TIMEOUT, WATCH_ALL, this.logoutAccount));
  }
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(removeTimeout(WATCH_ALL));
  }
  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    date: Date.now(),
    profile: state.account.get("profile")
  };
}

export default withAlerts(connect(mapStateToProps)(AccountMonitor));
