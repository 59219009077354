import React, { Component } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";

import classNames from "classnames";

import { logOut } from "../reducers/account";

import NavigationBurger from "./NavigationBurger";

import withAlerts from "./withAlerts";

class NavigationBarCoupon extends Component {
  state = {
    active: false
  };

  toggleMenu = event => {
    event.preventDefault();
    this.setState({ active: !this.state.active });
  };

  onClose = event => {
    event.preventDefault();
    const { dispatch, alerts, profile } = this.props;
    
    dispatch(replace("/"));
     setTimeout(() => {
      dispatch(logOut());
    }, 500);
  };

  render() {
    const { profile } = this.props;
    const { active } = this.state;
    const menuClasses = classNames("navbar-menu", { "is-active": active });
    return (
      <nav className="navbar is-dark">
        <div className="navbar-brand">
          <a className="navbar-item">Autogestión</a>
          <a className="navbar-item">{profile ? profile.name : ""}</a>
          <NavigationBurger onClick={this.toggleMenu} />
        </div>
        <div className={menuClasses}>
          <div className="navbar-end">
            <a className="navbar-item" onClick={this.onClose}>
              Volver a Inicio
            </a>
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.account.get("profile").toJS()
  };
}

export default withAlerts(connect(mapStateToProps)(NavigationBarCoupon));
