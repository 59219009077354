import React from "react";

const NoReclaims = () => {
  return (
    <React.Fragment>
      
      <p className="title is-size-4-mobile has-text-centered">No hay reclamos pendientes en este servicio.</p>
    </React.Fragment>
  );
};

export default NoReclaims;