import React, { Component } from "react";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faPrint from "@fortawesome/fontawesome-free-solid/faPrint";
import faEye from "@fortawesome/fontawesome-free-solid/faEye";
import faPhone from "@fortawesome/fontawesome-free-solid/faPhone";
import faUser from "@fortawesome/fontawesome-free-solid/faUser";
import faArrowLeft from "@fortawesome/fontawesome-free-solid/faArrowLeft";

import Server from "../utils/server";
import { Link } from "react-router-dom";
import CouponDialog from "../components/CouponDialog"


class CouponsPage extends Component {




  state = {

    telefono: "",

   // selectedOption: 'telefono',

    error: "",
  
    coupon: null,
    items: null,
    loading: true,
    
    


  }

  componentDidMount(){
    const {loading} = this.state;
    

}




  formRef = React.createRef();


  onPhoneChange = event => {

    if (!/^[ 0-9]*$/i.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^ 0-9]+/ig, "");
    }
    this.setState({ telefono: event.target.value });
    console.log(event.target.value);
  };


  onClick= event => {

    this.setState({
      items:null
    }
    )
    
  };
  onRadioChange = event => {


    this.setState({ selectedOption: event.target.value, telefono:"" });
    console.log(event.target.value);
  };




  onSubmit = event => {
    event.preventDefault();
    this.setState({
      error: ''
    });

    const { telefono, selectedOption } = this.state;

    if (!telefono || telefono.length >6 ) {
     
      this.setState({
        error:"Error: Número inválido"
      })
    } else {

      Server.loadCoupons(telefono)
        .then(items => {

          let coupon = items[0];
          

          this.setState({
            items,
           coupon,
           telefono : ""
          })




        })
        .catch(error => {
          this.setState({
            error: error + '',
            items:null,
            telefono: ""
          })
          return;
        });

    }
  };




  render() {
    const { items, coupon,telefono} = this.state;
    if (items== null){
    return(
      <div className="card coupon-card">
        <div className="card-content">
          <section style={{ border: "solid 2px", padding: "15px" }}>
      <h1 className="title is-2 has-text-centered">
      CUPONES DE PAGO
  </h1>
  <form
    method="POST"
    onSubmit={this.onSubmit}
    autoComplete="off"
    ref={this.formRef}
  >


    <hr />
    
    <p className="has-text-centered title">
      Ingrese Teléfono ó Nro. de Cliente
  </p>
    {/*
    <p className="has-text-centered">
      Buscar por :
  </p>
    <div className="has-text-centered">
      
        <input type="radio" name="radioPhone" value="telefono " id="telfono" defaultChecked  onChange={this.onRadioChange} />
        <label  className="radio title is-5">&nbsp; Teléfono  </label>

        &nbsp;&nbsp;
        <input type="radio" name="radioPhone" value="nroCliente" onChange={this.onRadioChange} className="button" />
        <label className="radio title is-5" >  &nbsp; Nro. Cliente  </label>
    </div>
    */}

   
    <hr />
    <div className="is-flex-centered">
      <div className="column is-10 ">

        <div className="control has-icons-left has-icons-right">
          <input className="input is-medium" type="number" onChange={this.onPhoneChange} value={telefono}/>
          <span className="icon is-small is-left">
            {/* this.state.selectedOption == 'nroCliente' ?
            (<FontAwesomeIcon icon={faUser} />) : ( <FontAwesomeIcon icon={faPhone} />)

  */}
  <FontAwesomeIcon icon={faUser} />
          </span>
        </div>
      </div>
    </div>
    <section className="section">
      <div className="is-flex-centered">

        <div className="control">


          <button className="button is-primary is-outlined is-medium" type="submit" >Consultar</button>
        </div>

      </div>
    </section>
  
    <p className="subtitle is-3">{this.state.error}</p>
  </form>
  </section>
        </div>

      </div>
    )
  }else if(items != null) {

    
    return (
     
      <div className="card coupon-card">
        <div className="card-content">
        
          <section style={{ border: "solid 2px", padding: "15px" }}>
          <div className="is-flex-container is-flex-vertical is-flex-centered">

              <CouponDialog url= {coupon.bills.current} client={coupon.clientId} setItems={this.onClick}/> 
              <br/> <br/>

            
             
              </div>
          </section>
         
        </div>
      </div>
      
  


    );
  }
  }
}














export default CouponsPage;
