import React from "react";




const ConsumptionCard = ({ items, name }) => {

  function validConsumption(consumption) {
    return consumption.line === name;
  }

  function inArray(needle, haystack) {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
      if (haystack[i] == needle) return true;
    }
    return false;
  }



  const lineConsumptions = items.filter(validConsumption);

  let totalPeriods = [];
  for (let consumption of lineConsumptions) {
    totalPeriods.push(consumption.period);
  }

  let validPeriods = [];

  for (let period of totalPeriods) {
    if (validPeriods.length === 0) {
      validPeriods.push(period);
    } else {
      if (!inArray(period, validPeriods)) {
        validPeriods.push(period);
      }
    }

  }







  return (
    <React.Fragment>
      <h1 className="title is-size-3-mobile is-size-3-tablet has-text-centered">
        Consumos
      </h1>
      <h1 className="subtitle is-size-4-mobile is-size-4-tablet has-text-centered">
        Linea {name}
      </h1>
      {validPeriods.map((period, i) => {
        let periodo = null;
        switch (period.substr(0, 3)) {
          case "Ene":
            periodo = "Enero";
            break;
          case "Feb":
            periodo = "Febrero";
            break;
          case "Mar":
            periodo = "Marzo";
            break;
          case "Abr":
            periodo = "Abril";
            break;
          case "May":
            periodo = "Mayo";
            break;
          case "Jun":
            periodo = "Junio";
            break;
          case "Jul":
            periodo = "Julio";
            break;
          case "Ago":
            periodo = "Agosto";
            break;
          case "Sep":
            periodo = "Septiembre";
            break;
          case "Oct":
            periodo = "Octubre";
            break;
          case "Nov":
            periodo = "Noviembre";
            break;
          case "Dic":
            periodo = "Diciembre";
            break;
        }

       

        return (
          <div key={'per-' + i} className="field">
            <h1 className="subtitle is-size-4-mobile is-size-3-tablet  has-text-centered">
              Período : {periodo}
            </h1>
            <div className="table-wrapper">
              <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">





                <thead>
                  <tr>

                    <th>Descripción</th>
                    <th>Llamadas</th>
                    <th>Tiempo</th>

                  </tr>
                </thead>
                <tbody>


                  {lineConsumptions.map((consumption, i) => {
                    if (consumption.period === period) {
                      return (

                        <tr key={'con-' + i}>
                          <td >{consumption.description}</td>
                          <td>
                            {consumption.calls}
                          </td>
                          <td>
                            {consumption.time}
                          </td>

                        </tr>
                      );
                    }
                  })}



                </tbody>


              </table>
            </div>
          </div>

        );

      })}
    </React.Fragment>
  );
};

export default ConsumptionCard;
