import React from "react";
import { CSSTransition } from "react-transition-group";

import "./MoveDownTransition.css";

const MoveDownTransition = ({ children, ...props }) => {
  return (
    <CSSTransition {...props} classNames="move-down" timeout={500}>
      {children}
    </CSSTransition>
  );
};

export default MoveDownTransition;
