import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import LoadingView from "./LoadingView";

const RequireLoadPage = ({ children, loading }) => {
  return (
    <TransitionGroup className="flex-container flex-expand is-flex-vertical absolute-fill">
      <CSSTransition classNames="move-down" timeout={500} key="view">
        <div className="flex-container is-flex-vertical flex-expand  auto-scroll has-small-padding main-background">
          {children}
        </div>
      </CSSTransition>
      {loading && <LoadingView key="loader" absolute />}
    </TransitionGroup>
  );
};

export default RequireLoadPage;
