import React, { Component } from "react";
import { connect } from "react-redux";

import RequireLoadPage from "../components/RequireLoadPage";
import Select from 'react-select';
import withAlerts from "../components/withAlerts"
import faPhone from "@fortawesome/fontawesome-free-solid/faPhone";
import faEye from "@fortawesome/fontawesome-free-solid/faEye";
import { startLoad, endLoadFail, endLoadSuccess } from "../reducers/reclaims";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import Server from "../utils/server";
import { Link } from "react-router-dom";

class NewReclaimPage extends Component {




  state = {
    name: null,
    token: null,
    selectedOption: null,
    telefono: "",
    hora1: null,
    hora2: null,
    hora3: null,
    hora4: null,



  }






  formRef = React.createRef();


  componentDidMount() {
    const { name } = this.props.match.params;

    const { dispatch, token } = this.props;
    this.setState({ token, name })
    dispatch(startLoad());
    Server.loadReclaims(token, name)
      .then(items => {
        setTimeout(() => dispatch(endLoadSuccess(items)), 1000);

      })
      .catch(e => {
        console.error(e);
        setTimeout(() => dispatch(endLoadFail()), 1000);
      });
  }


  onSelectChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  }


  onPhoneChange = event => {
    this.setState({ telefono: event.target.value });
    console.log(event.target.value);
  };

  onTimeChange1 = (event) => {
    this.setState({ hora1: event.target.value })
    console.log(event.target.value);
  }


  onTimeChange2 = (event) => {
    this.setState({ hora2: event.target.value })
    console.log(event.target.value);
  }


  onTimeChange3 = (event) => {
    this.setState({ hora3: event.target.value })
    console.log(event.target.value);
  }


  onTimeChange4 = (event) => {
    this.setState({ hora4: event.target.value })
    console.log(event.target.value);
  }




  onSubmit = event => {
    event.preventDefault();

    const { alerts, items } = this.props;
    const { name, token, selectedOption, telefono, hora1, hora2, hora3, hora4 } = this.state;

    if (!selectedOption) {
      alerts.danger(`Debe seleccionar una opcion`);



    } else if (!hora1 || !hora2) {


      alerts.danger(`Debe completar el horario`);




    } else if (hora1 > hora2) {


      alerts.danger(`Los horarios no son correctos!`);




    } else if (!telefono) {
      alerts.danger(`Debe ingresar un telefono`);

    } else {

      Server.saveReclaims(token, name, selectedOption, telefono, hora1, hora2)
        .then(mensaje => {

          console.log(mensaje);
          alerts.success(`El reclamo se ha cargado exitosamente!`);
          this.props.history.push(`/app/reclamos/ver/${name}`);

        })
        .catch(error => {

        });

    }
  };





  render() {
    const { loading, items } = this.props;
    const { name } = this.props.match.params;
    const { rate } = this.props.match.params;
    const servicio = validRate(rate);
    if (items.length > 0) {
      return (
        <RequireLoadPage loading={loading}>
          <div className="card">
            <div className="card-content">
              <p className="title is-3 has-text-centered"><span className="has-text-primary">Atención!</span> Ya existe un reclamo pendiente para este servicio</p>



              <div className="content has-text-centered">
                <Link
                  to={`/app/reclamos/ver/${name}`}
                  className="button is-info is-outlined is-medium"
                >
                  <FontAwesomeIcon icon={faEye} /> &nbsp; Ver Reclamo Pendiente
                </Link>
              </div>





            </div>
          </div>
        </RequireLoadPage>
      )
    } else {
      return (


        <RequireLoadPage loading={loading}>





          <div className="card"  >
            <div className="card-content">
              <section style={{ border: "solid 2px", padding: "15px" }}>
                <form
                  method="POST"
                  onSubmit={this.onSubmit}
                  autoComplete="off"
                  ref={this.formRef}
                >



                  <h1 className="title is-size-3-mobile has-text-centered" style={{ padding: "5px" }}>
                    NUEVO RECLAMO
      </h1>
                  <h2 className="subtitle  is-size-4-mobile  has-text-centered">
                    Servicio: {name}
                  </h2>
                  <h2 className="subtitle  is-size-4-mobile  has-text-centered">
                    {servicio}
                  </h2>


                  <div className="field has-text-centered">


                    <p className="subtitle is-size-5-mobile is-italic">Inconveniente</p>
                  </div>


                  <div className="is-flex-centered">
                    <div className="column is-5 ">

                      <div className="control">
                        <Select
                          isSearchable={false}
                          placeholder='Seleccione inconveniente..'
                          options={llenarSelect(name)}
                          value={this.state.selectedOption}
                          onChange={this.onSelectChange}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />


                  <div className="field  has-text-centered is-hidden-mobile">
                    <p className="subtitle is-4 is-italic">Disponibilidad</p>





                    {/*
     <div className="field has-text-centered">
     <label className="title is-6">Primer Horario</label>
     
    
    </div>
    */}

                    <div className="is-flex-centered" style={{ padding: "10px" }}>
                      <div className="field is-grouped">
                        <label className="subtitle is-6">Desde &nbsp;</label>


                        <input type="time" className="control" onChange={this.onTimeChange1} min="08:00" max="20:00" />

                        <label className="subtitle is-6">Hasta &nbsp;</label>

                        <input type="time" className="control" onChange={this.onTimeChange2} min="09:00" max="22:00" />


                      </div>
                    </div>
                  </div>


                  <div className="is-flex-centered is-flex-vertical">
                    <p className="subtitle is-size-5-mobile is-italic">Disponibilidad Horaria</p>





                    {/*
     <div className="field has-text-centered">
     <label className="title is-6">Primer Horario</label>
     
    
    </div>
    */}

                    <div className="is-flex-centered is-flex-vertical" >
                      
                        <label className="has-small-margin is-size-6-mobile">Desde</label>


                        <input type="time" className="control" onChange={this.onTimeChange1} min="08:00" max="20:00" />

                        <label className="has-small-margin is-size-6-mobile">Hasta</label>

                        <input type="time" className="control" onChange={this.onTimeChange2} min="09:00" max="22:00" />


                      </div>
                    </div>
                  



                  
                  <hr />

                  { /* 
        <div className="field has-text-centered">
     <label className="title is-6">Segundo Horario</label>
     
    
    </div>
    <div className="is-flex-centered">
    <div className="field is-grouped">
<label className="subtitle is-6">Desde &nbsp;</label>
  
  <input type="time"   className="control" onChange={this.onTimeChange3}/>

  <label className="subtitle is-6" >Hasta &nbsp;</label>
  
  <input type="time"  className="control" onChange={this.onTimeChange4} />
  
 
</div>
</div>
*/}
                  <div className="field has-text-centered ">


                    <p className="subtitle is-size-5-mobile is-italic">Teléfono de Contacto</p>
                  </div>


                  <div className="is-flex-centered">
                    <div className="column is-5 ">

                      <div className="control has-icons-left has-icons-right">
                        <input className="input is-medium" type="text" placeholder="Telefono" onChange={this.onPhoneChange} />
                        <span className="icon is-small is-left">
                          <FontAwesomeIcon icon={faPhone} />
                        </span>
                      </div>
                    </div>
                  </div>








                  <section className="section">
                    <div className="is-flex-centered">

                      <div className="control">


                        <button className="button is-primary is-outlined is-medium" type="submit" >Realizar Reclamo</button>
                      </div>
                    </div>
                  </section>









                </form>
              </section>
            </div>

          </div>




        </RequireLoadPage>



      );
    }
  }
}

function llenarSelect(value) {

  if (isPhone(value)) {


    const options = [
      { value: '1', label: 'No tiene tono' },
      { value: '2', label: 'Ligado o con ruido' },
      { value: '3', label: 'Problemas en la comunicacion' },
      { value: '4', label: 'Otro problema' }
    ];
    return options;
  }


  if (isSecurity(value)) {


    const options = [
      { value: '1', label: 'No activa' },
      { value: '2', label: 'No funciona' },
      { value: '3', label: 'Otro' }

    ];
    return options;
  }


  if (isInternet(value)) {


    const options = [
      { value: '1', label: 'No conecta' },
      { value: '2', label: 'Error de configuracion' },
      { value: '3', label: 'Otro' }

    ];
    return options;
  }
}

function isPhone(value) {

  return value.startsWith('2255');
}

function isInternet(value) {
  return (/^[^-]+-(.*?)$/ig).test(value);
}

function isSecurity(value) {
  return value.startsWith('4');
}

function validRate(rate) {
  if (rate.startsWith('fo-')) {
    return rate.substr(0, rate.strpos('/'));
  }
  return rate;
}

function mapStateToProps(state) {
  const result = state.reclaims.toJS();
  result.token = state.account.get("profile").get("token");
  return result;
}









export default withAlerts(connect(mapStateToProps)(NewReclaimPage));
