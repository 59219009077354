import React from "react";

const NoServices = () => {
  return (
    <React.Fragment>
      <h3 className ="title is-size-3-mobile">Usted no tiene ningún servicio asignado</h3>
    </React.Fragment>
  );
};

export default NoServices;
