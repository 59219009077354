import React from "react";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";

const Field = ({ icon, name, label, type, value, onChange, autoComplete }) => {
  return (
    <div className="field">
      <label className="label is-large">{label}</label>
      <div className="control is-large has-icons-left">
        <input
          name={name}
          onChange={onChange}
          value={value}
          className="input is-large"
          type={type}
          autoComplete={autoComplete}
        />
        <span className="icon is-large is-left">
          <FontAwesomeIcon icon={icon} />
        </span>
      </div>
    </div>
  );
};

export default Field;
