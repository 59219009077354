import React, { Component } from "react";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";

import faKey from "@fortawesome/fontawesome-free-solid/faKey";
import faEyeOpen from "@fortawesome/fontawesome-free-solid/faEye";
import faEyeClose from "@fortawesome/fontawesome-free-solid/faEyeSlash";

class PasswordField extends Component {
  state = {
    visible: false
  };

  toggleText = event => {
    event.preventDefault();
    this.setState({ visible: !this.state.visible });
  };

  render() {
    const { visible } = this.state;
    const { name, label, value, onChange, autoComplete } = this.props;
    return (
      <div className="field">
        <label className="label is-large">{label}</label>
        <div className="control is-large has-icons-left has-icons-right">
          <input
            name={name}
            onChange={onChange}
            value={value}
            className="input is-large"
            type={visible ? "text" : "password"}
            autoComplete={autoComplete}
          />
          <span className="icon is-large is-left">
            <FontAwesomeIcon icon={faKey} />
          </span>
          <span className="icon is-large is-right is-clickable">
            <div onClick={this.toggleText}>
              <FontAwesomeIcon icon={visible ? faEyeClose : faEyeOpen} />
            </div>
          </span>
        </div>
      </div>
    );
  }
}

export default PasswordField;
