import React from "react";

import { NavLink } from "react-router-dom";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";

const SidebarLink = ({ icon, to, title, onClick}) => {
  return (
    <NavLink
      className="panel-block is-big"
      activeClassName="is-active"
      exact
      to={to}
      onClick={onClick}
    >
      <span className="panel-icon">
        <FontAwesomeIcon icon={icon} />
      </span>
      {title}
    </NavLink>
  );
};

export default SidebarLink;
