import React, { Component } from "react";
import { TransitionGroup } from "react-transition-group";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";

import { Notifs } from "redux-notifications";

import LoadingView from "./components/LoadingView";

import Routes from "./Routes";

import store, { history } from "./store";

import "./App.css";

class App extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 200);
  }

  render() {
    const { loading } = this.state;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <TransitionGroup className="app">
            <Routes />
            {loading ? <LoadingView absolute /> : null}
            <Notifs />
          </TransitionGroup>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
