import React, { Component } from "react";

import classNames from "classnames";

import LoadingView from "./LoadingView";


import { Link } from "react-router-dom";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faLock from "@fortawesome/fontawesome-free-solid/faLock";
import faPhone from "@fortawesome/fontawesome-free-solid/faPhone";
import faPhoneVolume from "@fortawesome/fontawesome-free-solid/faPhoneVolume";
import faGlobe from "@fortawesome/fontawesome-free-solid/faGlobe";
import faTachometer from "@fortawesome/fontawesome-free-solid/faTachometerAlt";
import faPlug from "@fortawesome/fontawesome-free-solid/faPlug";
import faPlus from "@fortawesome/fontawesome-free-solid/faPlus";
import faMinus from "@fortawesome/fontawesome-free-solid/faMinus";
import faEye from "@fortawesome/fontawesome-free-solid/faEye";

class ServiceCardMobile extends Component {
  state = {
    active: false,
    
  };

  
  onClick = () => {
    this.setState({ active: !this.state.active });
  };



  render() {
    const { active } = this.state;
    const { icon,name,rate,serv } = this.props;
   
    return (
      <React.Fragment>
      <div className="columns">
                <FontAwesomeIcon icon={icon} />
                <p className="title is-4 inline">{name}</p>
                </div>
               
                
            <p className="subtitle is-4">{rate}</p>

            <button className="button is-light is-medium action-button" onClick={this.onClick}> + Detalles </button>
           {active === true ?(<div className="toggle-actions">
            {icon === faPhone ? (
                <p className="control">
              <Link
                to={`/app/consumos/${name}`}
                className="button is-link is-outlined is-medium"
              >
                <FontAwesomeIcon icon={faPhoneVolume} /> &nbsp; Ver Consumo
         </Link></p>) : (<p>&nbsp;</p>)

            }
                {icon === faPhone ? (
          
                <p className="control">
                  <Link
                    to={`/app/facilidades/ver/inactivas/${name}`}
                    className="button is-primary is-outlined is-medium"
                  >
                    <FontAwesomeIcon icon={faPlus} /> &nbsp; Activar
                    Servicios
                  </Link>
                 
                  <Link
                    to={`/app/facilidades/ver/activas/${name}`}
                    className="button is-primary is-outlined is-medium"
                  >
                    <FontAwesomeIcon icon={faMinus} /> &nbsp; Desactivar
                    Servicios
                  </Link>
                </p>
) : null}
              <p className="control">
                <Link
                  to={`/app/reclamos/ver/${name}`}
                  className="button is-info is-outlined is-medium"
                >
                  <FontAwesomeIcon icon={faEye} /> &nbsp; Ver Reclamos
                </Link>
              </p>
              <p className="control">
                <Link
                  to={`/app/reclamos/nuevo/${name}/${serv}`}
                  className="button is-danger is-outlined is-medium"
                >
                  <FontAwesomeIcon icon={faPlus} /> &nbsp; Realizar Reclamo
                </Link>
              </p>

                  </div>) : null }
            
      </React.Fragment>
    );
  }
}






export default ServiceCardMobile;
