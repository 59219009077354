import React, { Component } from "react";

import classNames from "classnames";
import { Link } from "react-router-dom";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import faPrint from "@fortawesome/fontawesome-free-solid/faPrint";
import faEye from "@fortawesome/fontawesome-free-solid/faEye";
import PDFView from "./PDFView";
import Server from "../utils/server";
import faArrowLeft from "@fortawesome/fontawesome-free-solid/faArrowLeft";
class CouponDialog extends Component {
  state = {
    open: false,
    loading: false
  };

  componentDidMount() {
    const { url } = this.props;
    console.log(url);
    try {
      window.Android.downloadPDF(url);
    } catch (error) {
      console.log(error);
    }

  }
  toggleModal = () => {
    const { url } = this.props;

    this.setState({ open: !this.state.open });

  };

  setLoading = (bool) => {
    this.setState({ loading: bool })
  }


  printBill = () => {
    //this.setState({ open: !this.state.open });
    const { url, setItems } = this.props;
    print(url, setItems, this.setLoading);
    // imprimir();

  };

  viewBill = () => {
    //this.setState({ open: !this.state.open });

    verPdf();


  };

  onLoad = () => {
    this.setState({ loading: false });
  };



  render() {
    const { open, loading } = this.state;
    const { url, client } = this.props;
    const classes = classNames("modal", {
      "is-active": open
    });

    if (loading) {
      return (<React.Fragment>
        <p className="title is-3">Imprimiendo cupon.. Espere unos segundos </p>
      </React.Fragment>

      );
    } else {
      return (
        <React.Fragment>



          <p className="title is-2 is-italic">Cupon Encontrado</p>

          <p className="title is-3">Nro. Cliente: {client} </p>
          <div className="control">


            <button
              type="button"
              className="button is-medium is-link is-outlined"
              onClick={this.viewBill}
            >
              <FontAwesomeIcon icon={faEye} /> &nbsp;  Ver
        </button>
            &nbsp;  &nbsp;
    
        <button
              type="button"
              className="button is-medium is-danger is-outlined"
              onClick={this.printBill}
            >
              <FontAwesomeIcon icon={faPrint} />  &nbsp; Imprimir
     
     
            </button>
          </div>
          <br /> <br />
          <div className="control">
            <button className="button is-success is-outlined is-medium" onClick={this.props.setItems}>  <FontAwesomeIcon icon={faArrowLeft} /> &nbsp;Volver a Consultar</button>
          </div>



        </React.Fragment>
      );
    }
  }
}

async function verPdf() {
  try {
    window.Android.viewPDF();
  } catch (error) {
    console.log(error);
  }


}
async function imprimir() {

  try {
    window.Android.printPDF();
  } catch (error) {
    console.log(error);
  }
}

async function print(url, setItems, setLoading) {

  try {
    const printerId = window.Android.printerId();
   
        setLoading(true);

        Server.printBill(url, printerId)
          .then(json => {
           
            if(json.ok == true){
         


            setTimeout(function() {
              setLoading(false);

              setItems();
           }, 5000);
            }else {
              window.Android.showToast(json.msg);
              setLoading(false);
              setItems();
            }
          

          }).catch(msg => {
            setLoading(false);
            setItems();
            

          })
      
     



  } catch (error) {
    console.log(error);
  }
}



export default CouponDialog;