import React from "react";

import DebtCard from "./DebtCard";

const DebtList = ({ items }) => {
  return (
    <React.Fragment>
      
        <DebtCard items={items} />
      {console.log(items)}
    </React.Fragment>
  );
};

export default DebtList;
