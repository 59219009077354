import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { reducer as notifReducer } from "redux-notifications";
import { reduxTimeout } from "redux-timeout";

import account from "./reducers/account";
import services from "./reducers/services";
import bills from "./reducers/bills";
import debts from "./reducers/debts";
import reclaims from "./reducers/reclaims";
import facilities from "./reducers/facilities";
import disabledFacilities from "./reducers/disabledFacilities";
import consumptions from "./reducers/consumptions";
import createHistory from "history/createBrowserHistory";

const reducer = combineReducers({
  // Reducers de la aplicacion
  account,
  services,
  bills,
  debts,
  reclaims,
  facilities,
  disabledFacilities,
  consumptions,
 
  
  // reducers de terceros
  router: routerReducer,
  notifs: notifReducer
});

const history = createHistory();

//history.replace("/");

//@ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = composeEnhancers(
  applyMiddleware(routerMiddleware(history), reduxTimeout())
);

const store = createStore(reducer, middleware);

export { history };

export default store;
